import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { registerApi } from "../../services/registerApi";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

function CreationUserModal({ onClose }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("usuario");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCriteria, setShowPasswordCriteria] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const passwordInputRef = useRef(null);
  const toggleButtonRef = useRef(null);

  // const handleBackdropClick = (event) => {
  //   if (event.target.classList.contains("modal-backdrop")) {
  //     onClose();
  //   }
  // };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      number: /\d/.test(password),
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      specialChar:
      /[!@#$%^&*(),.?":{}|<>_ ]/.test(password) ||
      (/[^ ]/.test(password) && password.trim() !== password),
    };
    return criteria;
  };

  const handleFocus = () => {
    setShowPasswordCriteria(true);
  };

  const handleBlur = (event) => {
    if (
      toggleButtonRef.current &&
      toggleButtonRef.current.contains(event.relatedTarget)
    ) {
      return;
    }
    setShowPasswordCriteria(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      toast.dismiss();
      toast.warning("Creando usuario...");
      const result = await registerApi(
        username,
        password,
        email,
        role === "administrador" ? "admin" : "user"
      );
      if (result.status === 204) {
        toast.dismiss();
        toast.success("Usuario creado exitosamente");
        onClose();
      } else {
        toast.error("Error al crear el usuario else");
      }
    } catch (error) {
      console.error("Error al crear el usuario:", error.message);
      let errorMessage = "Error desconocido";
      try {
        const errorResponse = JSON.parse(error.message);
        errorMessage = errorResponse.error_message || errorMessage;
      } catch (parseError) {
        console.error("Error parsing error response:", parseError);
      }
      toast.dismiss();
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const criteria = validatePassword(password);
    setIsPasswordValid(Object.values(criteria).every(Boolean));
  }, [password]);

  const passwordCriteria = validatePassword(password);

  return ReactDOM.createPortal(
    <div
      className="modal-backdrop absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      // onClick={handleBackdropClick}
    >
      <div className="bg-white p-5 rounded-lg w-full max-w-lg mx-4 h-auto flex flex-col">
        <h2 className="text-xl font-bold mb-4">Crear Usuario</h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <label className="font-bold">
            Usuario: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="p-2 border rounded"
            required
          />

          <label className="font-bold">
            Contraseña Temporal: <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Contraseña"
              value={password}
              onChange={handlePasswordChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className="p-2 border rounded w-full"
              ref={passwordInputRef}
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              aria-label={
                showPassword ? "Ocultar contraseña" : "Mostrar contraseña"
              }
              ref={toggleButtonRef}
            >
              {showPassword ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-700" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-700" />
              )}
            </button>
          </div>
          {showPasswordCriteria && (
            <div className="text-sm text-gray-600">
              <ul className="list-disc list-inside">
                <li>
                  <input
                    type="checkbox"
                    readOnly
                    checked={passwordCriteria.length}
                  />
                  Longitud mínima de 8 caracteres
                </li>
                <li>
                  <input
                    type="checkbox"
                    readOnly
                    checked={passwordCriteria.number}
                  />
                  Contiene al menos 1 número
                </li>
                <li>
                  <input
                    type="checkbox"
                    readOnly
                    checked={passwordCriteria.lowercase}
                  />
                  Contiene al menos una letra minúscula
                </li>
                <li>
                  <input
                    type="checkbox"
                    readOnly
                    checked={passwordCriteria.uppercase}
                  />
                  Contiene al menos una letra mayúscula
                </li>
                <li>
                  <input
                    type="checkbox"
                    readOnly
                    checked={passwordCriteria.specialChar}
                  />
                  Contiene al menos 1 carácter especial o un carácter de espacio
                  que no es inicial ni final
                </li>
              </ul>
            </div>
          )}

          <label className="font-bold">
            Correo Electronico: <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            placeholder="Correo Electronico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 border rounded"
            required
          />

          <label className="font-bold">
            Rol: <span className="text-red-500">*</span>
          </label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="p-2 border rounded"
          >
            <option value="usuario">Usuario</option>
            <option value="administrador">Administrador</option>
          </select>

          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className={`py-2 px-6 rounded-lg transition duration-150 ${
                isPasswordValid && !isSubmitting
                  ? "bg-blue-500 text-white hover:bg-blue-700"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
              disabled={!isPasswordValid || isSubmitting}
            >
              Crear
            </button>
            <button
              type="button"
              onClick={onClose}
              className="py-2 px-6 rounded-lg bg-red-500 text-white hover:bg-red-700 transition duration-150 ml-2"
            >
              Cerrar
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default CreationUserModal;