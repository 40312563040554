import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";


async function getCollection() {
  const response = await fetch('/configuration.json');
  const data = await response.json();
  return data.COLLECTION;
}


async function getUploadFilesUrl(collection, fileName, fileType) {
  const response = await fetch('/api.json');
  const data = await response.json();
  return `${data.API_UPLOAD_FILES}?collection=${encodeURIComponent(collection)}&filename=${encodeURIComponent(fileName)}&content_type=${encodeURIComponent(fileType)}`;
}

async function getFetchUserDocumentsUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_FETCH_USER_DOCUMENTS;
}

async function getFetchChatHistoryUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_FETCH_CHAT_HISTORY;
}

async function getDeleteFileUrl(fileHash, collectionName) {
  const response = await fetch('/api.json');
  const data = await response.json();
  return `${data.API_DELETE_FILES}?file_hash=${encodeURIComponent(fileHash)}&collection=${encodeURIComponent(collectionName)}`;
}

export const uploadMultipleFiles = async (files) => {
  const token = Cookies.get("accessToken");
  const decodedToken = jwtDecode(token);

  const userGroups = decodedToken["cognito:groups"];
  if (!userGroups.includes("admin")) {
    throw new Error("Se requieren privilegios de administrador.");
  }

  try {
    const collection = await getCollection(); 

    for (const file of files) {
      const fileName = encodeURIComponent(file.name);
      const fileType = file.type;
      const getUploadUrl = await getUploadFilesUrl(collection, fileName, fileType);

      const responseUrl = await fetch(getUploadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!responseUrl.ok) {
        throw new Error("Error al obtener la URL de subida.");
      }
      const uploadUrl = await responseUrl.text();
      const responseUpload = await fetch(uploadUrl, {
        method: "PUT",
        body: file,
        headers: {
          'Content-Type': fileType,
        },
      });

      if (!responseUpload.ok) {
        throw new Error("Error al subir el archivo.");
      }
    }

    return "Todos los archivos fueron subidos exitosamente.";
  } catch (error) {
    console.error("Error en el proceso de subida de archivos:", error);
    throw error;
  }
};

export const getUserDocuments = async () => {
  const token = Cookies.get("accessToken");

  try {
    const API_FETCH_USER_DOCUMENTS = await getFetchUserDocumentsUrl();
    const API_URL = API_FETCH_USER_DOCUMENTS; 

    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error al obtener los documentos");
    }

    const documents = await response.json();
    return documents;
  } catch (error) {
    console.error("Error al intentar obtener los documentos:", error);
    throw error;
  }
};



export const fetchChatHistory = async (topic) => {
  const token = Cookies.get("accessToken");

  try {
    const API_FETCH_CHAT_HISTORY = await getFetchChatHistoryUrl();
    const API_URL = `${API_FETCH_CHAT_HISTORY}?topic=${topic}`;

    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error al cargar el historial del chat");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error al intentar obtener el historial del chat:", error);
    throw error;
  }
};

export const deleteFile = async (fileHash, collectionName) => {
  const token = Cookies.get("accessToken");
  if (!token) {
    throw new Error(
      "No se encontró el token de acceso. Por favor, inicie sesión nuevamente."
    );
  }

  const decodedToken = jwtDecode(token);
  const userGroups = decodedToken["cognito:groups"];
  if (!userGroups || !userGroups.includes("admin")) {
    throw new Error(
      "Usuario no autorizado. Se requieren privilegios de administrador."
    );
  }

  try {
    const API_DELETE_FILES = await getDeleteFileUrl(fileHash, collectionName);
    const API_URL = API_DELETE_FILES;

    const response = await fetch(API_URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al eliminar el archivo: ${errorText}`);
    }
    return true;
  } catch (error) {
    console.error("Error al eliminar el archivo:", error);
    throw error;
  }
};