import Cookies from "js-cookie";

// Función para cargar el JSON y obtener el campo API_REGISTER
async function getRegisterUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_REGISTER;
}

export const registerApi = async (username, password, email, role) => {
    const token = Cookies.get("accessToken");
    const body = {
        username,
        password,
        email,
    };

    if (role === "admin") {
        body.groups = ["admin"];
    }

    try {
        const API_REGISTER = await getRegisterUrl();
        const API_URL = API_REGISTER; 

        const response = await fetch(API_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            console.error('Error en la respuesta:', errorResponse);
            throw new Error(JSON.stringify(errorResponse));
        }

        return response;
    } catch (error) {
        console.error('Error al registrar usuario:', error.message);
        throw error;
    }
};
