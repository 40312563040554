import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDropzone } from "react-dropzone";
import { getUserDocuments, uploadMultipleFiles, deleteFile } from "../../services/uploadFilesApi";
import { RiUpload2Line, RiDeleteBin6Line, RiFileAddLine, RiFolder3Line, RiArrowLeftLine } from "react-icons/ri";
import Pdf from "../../images/pdf.svg";
import Doc from "../../images/doc.svg";
import Txt from "../../images/txt.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getFileIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return Pdf;
    case 'doc':
    case 'docx':
      return Doc;
    case 'txt':
      return Txt;
    default:
      return Pdf;
  }
};

function FileManagementModal({ onClose }) {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userDocuments, setUserDocuments] = useState({});
  const [refreshDocuments, setRefreshDocuments] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [viewMode, setViewMode] = useState('icons');
  const [currentDirectory, setCurrentDirectory] = useState('root');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserDocuments = async () => {
      setIsLoading(true);
      try {
        const documents = await getUserDocuments();
        const groupedDocuments = documents.reduce((acc, doc) => {
          const collectionName = doc.COLLECTION_NAME || 'root';
          if (!acc[collectionName]) {
            acc[collectionName] = [];
          }
          acc[collectionName].push(doc);
          return acc;
        }, {});
        setUserDocuments(groupedDocuments);
      } catch (error) {
        console.error(error);
        toast.dismiss();
        toast.error("Error al obtener documentos.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDocuments();
  }, [refreshDocuments]);

  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files).filter(file =>
      ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'].includes(file.type)
    );
    if (newFiles.length !== event.target.files.length) {
      toast.error("Solo se permiten archivos PDF, DOC, DOCX y TXT.");
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (index) => {
    const newFiles = selectedFiles.filter((_, fileIndex) => fileIndex !== index);
    setSelectedFiles(newFiles);
  };

  const uploadSelectedFiles = async () => {
    toast.dismiss();
    const toastId = toast.warning("Cargando archivos, por favor espera...", {
      autoClose: false,
    });
    setIsUploading(true);
    try {
      const result = await uploadMultipleFiles(selectedFiles);
      toast.dismiss(toastId);
      toast.success("Archivos procesando.");
      setIsUploading(false);
      setSelectedFiles([]);
      setRefreshDocuments(!refreshDocuments);
    } catch (error) {
      console.error("Error al intentar cargar los archivos:", error);
      toast.dismiss();
      toast.error("Error al cargar archivos.");
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'text/plain': []
    },
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
  });

  const handleDeleteFile = async (fileHash, collectionName) => {
    toast.dismiss();
    const toastId = toast.warning("Eliminando archivos, por favor espera...", {
      autoClose: false,
    });
    try {
      await deleteFile(fileHash, collectionName);
      toast.dismiss(toastId);
      toast.success("Archivo eliminado con éxito");
      setRefreshDocuments((prev) => !prev);
    } catch (error) {
      toast.dismiss();
      toast.error("Error al eliminar el archivo");
    }
  };

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains('modal-backdrop') && !isUploading) {
      onClose();
    }
  };

  const handleDirectoryDoubleClick = (directory) => {
    setCurrentDirectory(directory);
  };

  const renderDocuments = () => {
    if (viewMode === 'list') {
      return (
        <ul>
          {userDocuments[currentDirectory].map((doc) => (
            <li key={doc.FILE_HASH} className="relative flex items-center bg-gray-100 p-2 rounded mb-2">
              <img src={getFileIcon(decodeURIComponent(doc.FILE_NAME))} alt="File Icon" className="h-8 w-8 mr-2" />
              <div className="flex-grow break-words truncate" title={decodeURIComponent(doc.FILE_NAME)}>
                {decodeURIComponent(doc.FILE_NAME)}
              </div>
              <button onClick={() => handleDeleteFile(doc.FILE_HASH, doc.COLLECTION_NAME)} className="text-red-500 hover:text-red-700 ml-2">
                <RiDeleteBin6Line className="text-lg" />
              </button>
            </li>
          ))}
        </ul>
      );
    }

    return (
      <div className="grid grid-cols-2 gap-4">
        {userDocuments[currentDirectory].map((doc) => (
          <div key={doc.FILE_HASH} className="relative flex flex-col bg-gray-100 p-2 rounded">
            <div className="flex items-center space-x-2">
              <img src={getFileIcon(decodeURIComponent(doc.FILE_NAME))} alt="File Icon" className="h-16 w-16" />
              <div className="flex-grow break-words truncate" title={decodeURIComponent(doc.FILE_NAME)}>
                {decodeURIComponent(doc.FILE_NAME)}
              </div>
            </div>
            <div className="text-sm text-gray-500">
              {doc.COLLECTION_NAME}
            </div>
            <button onClick={() => handleDeleteFile(doc.FILE_HASH, doc.COLLECTION_NAME)} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
              <RiDeleteBin6Line className="text-lg" />
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderDirectories = () => {
    return (
      <div className="grid grid-cols-2 gap-4">
        {Object.keys(userDocuments).map((collectionName) => (
          <div
            key={collectionName}
            className="relative flex flex-col bg-gray-100 p-2 rounded cursor-pointer"
            onDoubleClick={() => handleDirectoryDoubleClick(collectionName)}
          >
            <div className="flex items-center space-x-2">
              <RiFolder3Line className="h-16 w-16 text-yellow-500" />
              <div className="flex-grow break-words truncate" title={collectionName}>
                {collectionName}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return ReactDOM.createPortal(
    <div className="modal-backdrop absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={handleBackdropClick}>
      <div className="bg-white p-5 rounded-lg w-full max-w-2xl mx-4 md:mx-8 h-3/4 overflow-auto">
        <div className="text-center mb-4">
          <h2 className="text-lg font-bold">
            Menú de Administración de Archivos
          </h2>
        </div>

        <div className="flex justify-center mb-4">
          <button onClick={() => setViewMode('list')} className={`mr-2 p-2 rounded-lg ${viewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
            Lista
          </button>
          <button onClick={() => setViewMode('icons')} className={`p-2 rounded-lg ${viewMode === 'icons' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
            Iconos
          </button>
        </div>

        {currentDirectory !== 'root' && (
          <button onClick={() => setCurrentDirectory('root')} className="mb-4 p-2 rounded-lg bg-gray-200 hover:bg-gray-300">
            <RiArrowLeftLine className="inline-block mr-2" /> Atrás
          </button>
        )}

        {isLoading ? (
          <div className="text-center my-4">
            <p className="text-gray-600">Cargando...</p>
          </div>
        ) : currentDirectory === 'root' ? (
          <div className="directories mb-4">
            <h3 className="font-semibold mb-2">Directorios:</h3>
            {renderDirectories()}
          </div>
        ) : (
          <div className="previously-uploaded-documents mb-4">
            <h3 className="font-semibold mb-2">Documentos Subidos:</h3>
            {renderDocuments()}
          </div>
        )}

        {!userDocuments[currentDirectory]?.length && currentDirectory !== 'root' && !isLoading && (
          <div {...getRootProps()} className="text-center my-4 p-10 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer">
            <input {...getInputProps()} />
            <RiFileAddLine className="mx-auto text-gray-300" size="5em" />
            {isDragActive ? (
              <p className="text-gray-600 mt-4">Arrastra aquí</p>
            ) : (
              <>
                <p className="text-gray-600 mt-4">
                  No tienes documentos cargados aún.
                </p>
                <p className="text-gray-500">
                  Arrastra y suelta algunos archivos aquí, o haz clic para
                  seleccionar archivos
                </p>
              </>
            )}
          </div>
        )}

        <button onClick={() => fileInputRef.current && fileInputRef.current.click()} className={`file-select-btn mb-2 p-2 rounded-lg text-white transition duration-150 flex items-center gap-2 ${isUploading ? "bg-gray-500 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"}`} disabled={isUploading}>
          <RiUpload2Line className="text-lg" /> Seleccionar Archivos
        </button>
        <input type="file" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain" onChange={handleFileSelect} ref={fileInputRef} style={{ display: "none" }} multiple />
        <ul className="selected-files-list w-full">
          {selectedFiles.map((file, index) => (
            <li key={index} className="file-name py-1 px-2 rounded bg-gray-100 my-1 flex justify-between items-center">
              <span className="break-words truncate">
                {file.name} - {Math.round(file.size / 1024)} KB
              </span>
              <button onClick={() => removeFile(index)} className="text-red-500 hover:text-red-600">
                &times;
              </button>
            </li>
          ))}
        </ul>
        <button onClick={uploadSelectedFiles} className={`mr-2 p-2 rounded-lg text-white transition duration-150 ${isUploading || selectedFiles.length === 0 ? "bg-gray-500 cursor-not-allowed" : "bg-green-500 hover:bg-green-700"}`} disabled={isUploading || selectedFiles.length === 0}>
          Subir Archivos
        </button>
        <button onClick={onClose} className="mt-4 py-2 px-4 rounded-lg bg-red-500 text-white hover:bg-red-700 transition duration-150" disabled={isUploading}>
          Cerrar
        </button>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default FileManagementModal;
