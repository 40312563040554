import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextApp } from "../utils/Context";
//import IconChatContainer from "../images/iconchatcontainer.png";
import iconBot from "../images/botIcon.svg";
import iconUser from "../images/userIcon.svg";
import { Spin } from 'antd';

function Chat() {
  const {
    message,
    showSlide,
    currentTopic,
    loadChatHistoryForTopic,
    isWaitingForResponse,
    isAwaitingNewResponse,
  } = useContext(ContextApp);
  const endOfMessagesRef = useRef(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false);
  const messagesContainerRef = useRef(null);
  const [shouldLoadHistory, setShouldLoadHistory] = useState(false);

  const chatClass = `w-full h-[85%] overflow-hidden overflow-y-scroll ${
    showSlide ? "pl-12" : "pl-2"
  } pr-2`;

  const handleUserScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } =
        messagesContainerRef.current;
      if (scrollHeight - scrollTop > clientHeight * 1.5) {
        setShouldScrollToBottom(false);
      } else {
        setShouldScrollToBottom(true);
      }
    }
  };

  useEffect(() => {
    if (shouldScrollToBottom) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [message, shouldScrollToBottom]);

  useEffect(() => {
    if (currentTopic && shouldLoadHistory && !hasLoadedHistory) {
      loadChatHistoryForTopic(currentTopic);
      setHasLoadedHistory(true);
      setShouldLoadHistory(false);
    }
  }, [currentTopic, shouldLoadHistory, loadChatHistoryForTopic]);

  return (
    <div
      ref={messagesContainerRef}
      className={chatClass}
      onScroll={handleUserScroll}
    >
      {message && message.length > 0 ? (
        <div className="flex flex-col w-full justify-end pb-4">
          {message.map((msg, i) => (
            <div
              key={i}
              className={`w-full flex items-start mb-4 ${
                msg.isBot ? "bg-[#F3F3F3]" : "bg-white"
              }`}
            >
              <div
                className="flex w-full justify-center my-4 mx-auto"
                style={{ maxWidth: "45rem" }}
              >
                <div className="flex items-start w-full">
                  <img
                    src={msg.isBot ? iconBot : iconUser}
                    alt={msg.isBot ? "Bot" : "User"}
                    className="w-8 h-8 object-cover mr-2 mt-1"
                  />
                  <p
                    className="whitespace-pre-wrap flex-1 text-justify"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {typeof msg.text === 'string' && msg.isBot && msg.text.startsWith(" ")
                      ? msg.text.slice(1)
                      : msg.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
          {isAwaitingNewResponse && (
            <div className="flex justify-center items-center h-full">
              <Spin />
            </div>
          )}
          {/* ref al final de los mensajes para el auto-scroll */}
          <div ref={endOfMessagesRef} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <img
            //src={IconChatContainer}
            src="./static/media/iconchatcontainer.png"
            alt="Help Icon"
            className="max-w-20 max-h-20 w-auto h-auto mb-2"
          />
          <p className="text-black font-normal text-lg">
            ¿Cómo puedo ayudarte hoy?
          </p>
        </div>
      )}
    </div>
  );
}

export default Chat;